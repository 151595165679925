import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { constant } from '@config/BaseSetting';
import useLocalStorage from '@hooks/useLocalStorage';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { organizationInformationPath, resellersListPath } from '@config/Routes/WebClientRoutes';
import {
  setOrganizationDetailState,
  setPartnerDetailsState,
} from '@features/application/applicationSlice';
import { fetchBackupStatusListAsync } from '@features/backupStatus/backupStatusInfoSlice';
import { EMAIL } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { CONTACT } from '@pages/UserBackupPage/pageHooks/constants/contactConstants';
import { CALENDAR } from '@pages/UserBackupPage/pageHooks/constants/calendarConstants';
import { TASK } from '@pages/UserBackupPage/pageHooks/constants/taskConstants';
import { EMAIL_ARCHIVE } from '@pages/UserBackupPage/pageHooks/constants/emailArchiveConstants';
import { SD_DRIVE } from '@pages/UserBackupPage/pageHooks/constants/sdDriveConstants';
import { CCT_STATUS_COLUMNS } from './constants/cctStatusConstants';
import { EMAIL_STATUS_COLUMNS } from './constants/emailStatusConstants';
import { EMAIL_ARCHIVE_STATUS_COLUMNS } from './constants/emailArchiveStatusConstants';
import { SD_DRIVE_STATUS_COLUMNS } from './constants/sdDriveStatusConstants';

export default function useBackupStatusFetching(props) {
  const { defaultFilter, setFilterState, defaultPagination, setPaginationState } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessTokenSet] = useLocalStorage(constant.ACCESS_TOKEN_STORAGE);
  const [isReset, setIsReset] = useState(false);
  const [showMesageDialog, setShowMesageDialog] = useState(false);
  const [messageDialog, setMesageDialog] = useState({ title: '', message: '' });

  const handleFetchBackupStatusListAsync = (
    pageIndex,
    pageSize,
    resellerId,
    OrganizationOwnerId,
    backupType,
    filters
  ) => {
    dispatch(
      fetchBackupStatusListAsync({
        accessToken: accessTokenSet,
        reset: true,
        pageNumber: pageIndex,
        pageSize,
        resellerId,
        OrganizationOwnerId,
        backupType,
        filters,
      })
    );
  };

  const handleResetFilter = () => {
    setIsReset(true);
  };

  useEffect(() => {
    if (isReset) {
      setFilterState(defaultFilter);
      setPaginationState(defaultPagination);
    }
    setIsReset(false);
  }, [isReset]);

  const tableColumns = (backupType) => {
    let columns = [];
    switch (backupType) {
      case EMAIL:
        columns = EMAIL_STATUS_COLUMNS;
        break;
      case EMAIL_ARCHIVE:
        columns = EMAIL_ARCHIVE_STATUS_COLUMNS;
        break;
      case CONTACT:
        columns = CCT_STATUS_COLUMNS;
        break;
      case CALENDAR:
        columns = CCT_STATUS_COLUMNS;
        break;
      case TASK:
        columns = CCT_STATUS_COLUMNS;
        break;
      case SD_DRIVE:
        columns = SD_DRIVE_STATUS_COLUMNS;
        break;
      default:
        columns = EMAIL_STATUS_COLUMNS;
        break;
    }

    // add click able function
    return columns.map((item) => {
      if (item.columnName === 'organizationName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setOrganizationDetailState({
              organizationInformationPath,
              row: { organizationOwnerId: row.organizationOwnerId, orgName: row.organizationName },
            })
          );
          return navigate({
            pathname: organizationInformationPath,
            search: createSearchParams({
              id: row.organizationId,
              name: row.organizationName,
            }).toString(),
          });
        };
      }
      if (item.columnName === 'partnerName') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          handleResetFilter();
          dispatch(
            setPartnerDetailsState({
              resellersListPath,
              row: { id: row.partnerId },
            })
          );
          return navigate({
            pathname: resellersListPath,
            search: createSearchParams({
              id: row.partnerId,
              name: row.partnerName,
            }).toString(),
          });
        };
      }
      if (item.columnName === 'errorMessage' || item.columnName === 'lastXMLResponse') {
        // eslint-disable-next-line no-param-reassign
        item.onClickCell = (row) => {
          setShowMesageDialog(true);
          setMesageDialog({ title: item.label, message: row[item.columnName] });
          return '';
        };
      }

      return item;
    });
  };

  return {
    handleFetchBackupStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  };
}
