import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboTabs,
  QboFormGroup,
  QboModal,
  QboTypography,
} from '@ui/Components';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import DistributorFilterSection from '@ui/Sections/Shared/DistributorFilterSection';
import PartnerFilterSection from '@ui/Sections/Shared/PartnerFilterSection';
import OrganizationFilterSection from '@ui/Sections/Shared/OrganizationFilterSection';
import QboSortByButton from '@ui/Components/QboSortByButton';
import { t } from 'i18next';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  resetPartnerFilterState,
  resetOrganizationFilterState,
} from '@features/application/applicationSlice';
import { EMAIL, EMAIL_TEXT } from '@pages/UserBackupPage/pageHooks/constants/emailConstants';
import { CONTACT } from '@pages/UserBackupPage/pageHooks/constants/contactConstants';
import { CALENDAR } from '@pages/UserBackupPage/pageHooks/constants/calendarConstants';
import { TASK } from '@pages/UserBackupPage/pageHooks/constants/taskConstants';
import {
  EMAIL_ARCHIVE,
  EMAIL_ARCHIVE_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/emailArchiveConstants';
import {
  SD_DRIVE,
  SD_DRIVE_TEXT,
} from '@pages/UserBackupPage/pageHooks/constants/sdDriveConstants';
import useBackupStatusFetching from './pageHooks/useBackupStatusFetching';
import { systemStatusItems, buttonStyle } from './pageHooks/constants/emailStatusConstants';

export default function BackupStatusPage(props) {
  const { resellerId, organizationOwnerId } = props;
  const dispatch = useDispatch();
  const { backupStatusListState } = useSelector((state) => state.backupStatusState);
  const {
    fetching,
    backupStatusList,
    paginations,
    forbiddenErrorMessage,
    backupStatusListCorsErrorMessage,
  } = backupStatusListState;

  // fetching
  const defaultPagination = { pageNumber: 1, pageSize: 10 };
  const [paginationState, setPaginationState] = useState(defaultPagination);

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const defaultFilter = {
    status: '',
    startDate: dayjs().startOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    endDate: dayjs().endOf('month').format('YYYY-MM-DDTHH:mm:ss'),
    date: dayjs(),
    selectedPartnerId: resellerId,
    selectedOrganizationOwnerId: organizationOwnerId,
    selectedParentId: null,
  };
  const [filterState, setFilterState] = useState(defaultFilter);

  // tabs
  const tabNames = [EMAIL_TEXT, EMAIL_ARCHIVE_TEXT, CONTACT, CALENDAR, TASK, SD_DRIVE_TEXT];
  const [backupType, setBackupType] = useState(EMAIL);
  const [selectTab, setSelectTab] = useState(0);
  const convertToBackupType = (tabName) => {
    if (tabName === EMAIL_TEXT) {
      return EMAIL.toLowerCase();
    }
    if (tabName === EMAIL_ARCHIVE_TEXT) {
      return EMAIL_ARCHIVE.toLowerCase();
    }
    if (tabName === SD_DRIVE_TEXT) {
      return SD_DRIVE.toLowerCase();
    }
    return tabName.toLowerCase();
  };
  const handleOnTabClick = (e, newValue) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setSelectTab(newValue);
    const newBackupType = tabNames[newValue];
    setBackupType(convertToBackupType(newBackupType));
  };

  const {
    handleFetchBackupStatusListAsync,
    tableColumns,
    showMesageDialog,
    setShowMesageDialog,
    messageDialog,
  } = useBackupStatusFetching(defaultFilter, setFilterState, defaultPagination, setPaginationState);

  const fetchHandler = () => {
    setOnSearch(true);
    if (!fetching) {
      handleFetchBackupStatusListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        filterState.selectedPartnerId,
        filterState.selectedOrganizationOwnerId,
        backupType,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize });
  };

  const handleChangePageSize = (newPageSize) => {
    setPaginationState({ pageNumber: 1, pageSize: newPageSize });
  };

  const handleFilterdChange = (value, key) => {
    const newFilter = { ...filterState, [key]: value };
    setFilterState(newFilter);
  };

  const handleOnSearchClick = async (e) => {
    e.preventDefault();
    setPaginationState(defaultPagination);
    setOnSearch(true);
  };

  const handleOnResetButtonClick = (e) => {
    setPaginationState(defaultPagination);
    setFilterState(defaultFilter);
    setOnSearch(true);
    dispatch(resetPartnerFilterState());
    dispatch(resetOrganizationFilterState());
  };

  const handleOnChangeDate = (newDate) => {
    const startDate = newDate.startOf('month').format('YYYY-MM-DDTHH:mm:ss');
    const endDate = newDate.endOf('month').format('YYYY-MM-DDTHH:mm:ss');

    const newFilter = { ...filterState, startDate, endDate };
    setFilterState(newFilter);
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  useEffect(() => {
    setPaginationState(defaultPagination);
    fetchHandler();
  }, [backupType]);

  return (
    <>
      <HeaderPageBlock>Backup Status</HeaderPageBlock>
      {backupStatusListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {backupStatusListCorsErrorMessage}
        </QboAlert>
      )}
      <QboTabs
        noPadding
        isMainWrappe
        tabIndex={selectTab}
        title={tabNames}
        onChange={handleOnTabClick}
        sx={{ textTransform: 'capitalize', marginTop: 2 }}
      />
      <QboCard isMainWrapper>
        {![tabNames.indexOf(EMAIL_ARCHIVE_TEXT)].includes(selectTab) && (
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Period"
              views={['year', 'month']}
              value={filterState.date}
              defaultValue={dayjs()}
              maxDate={dayjs()}
              minDate={dayjs().subtract(2, 'year')}
              onChange={(value) => {
                handleOnChangeDate(value);
              }}
              sx={{ marginBottom: 2 }}
            />
          </LocalizationProvider>
        )}
        <QboFormGroup
          onSubmit={(e) => handleOnSearchClick(e)}
          buttonPostion="start"
          buttonStyle={buttonStyle}
          handleOnResetButton={() => {
            handleOnResetButtonClick();
          }}
          firstButtonLabel={t('partner_details_page.button.search')}
          withGenerateAndResetButton
          buttonLabel="Validate Transfer">
          <div>
            <QboSortByButton
              defaultValue="All"
              marginRightValue={2}
              value={filterState.status}
              buttonWidth={220}
              startAdornmentTextWidth={70}
              startAdornmentText="Status"
              handleChange={(e) => {
                handleFilterdChange(e.target.value, 'status');
              }}
              menuItemList={systemStatusItems}
            />
            {/* TODO: this only for example usage, will deleted it soon
              <DistributorFilterSection
              label="Distributor"
              inputSize={180}
              handleChange={(value) => {
                handleFilterdChange(parseInt(value, 10), 'selectedParentId');
              }}
            /> */}
            {!resellerId && !organizationOwnerId && (
              <PartnerFilterSection
                parentId={filterState.selectedParentId}
                label="Partners"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(parseInt(value, 10), 'selectedPartnerId');
                }}
              />
            )}
            {!organizationOwnerId && (
              <OrganizationFilterSection
                partnerId={filterState.selectedPartnerId}
                label="Organizations"
                inputSize={180}
                handleChange={(value) => {
                  handleFilterdChange(value, 'selectedOrganizationOwnerId');
                }}
              />
            )}
          </div>
        </QboFormGroup>
      </QboCard>

      <QboCard noPadding isMainWrapper>
        <>
          {(backupStatusList?.length || fetching) && (
            <QboSimpleDataTable
              sx={{ width: '100%' }}
              rows={backupStatusList}
              header={tableColumns(backupType)}
              loading={fetching}
            />
          )}
          {(backupStatusList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                />
              }
            />
          )}

          {!backupStatusList?.length && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}

          <QboModal
            classNameStyle="Qbo_model_error_message"
            title={messageDialog.title}
            showCloseIcon
            open={showMesageDialog}
            onClose={() => setShowMesageDialog(false)}>
            <QboTypography wrap={!false}>{messageDialog.message}</QboTypography>
          </QboModal>
        </>
      </QboCard>
    </>
  );
}

BackupStatusPage.propTypes = {
  resellerId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
};

BackupStatusPage.defaultProps = {
  resellerId: null,
  organizationOwnerId: null,
};
