import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import HeaderPageBlock from '@ui/Blocks/Shared/HeaderPageBlock';
import {
  QboSimpleDataTable,
  QboCard,
  QboAlert,
  QboPagination,
  QboCardListItem,
  QboTabs,
  QboTypography,
} from '@ui/Components';
import FilterSection from '@ui/Sections/UserBackupPage/FilterSection';
import NoStatusSection from '@ui/Sections/SystemStatusPage/NoStatusSection';
import UserEmailBackupToolsSection from '@ui/Sections/UserBackupPage/UserEmailBackupToolsSection';
import {
  setPaginationState,
  resetPaginationState,
  resetFilterState,
} from '@features/userBackup/userBackupInfoSlice';
import useFeatureFlag from '@hooks/useFeatureFlag';
import RequestModalTool from '@ui/Sections/TenantPage/RequestModalTool';
import { useTranslation } from 'react-i18next';
import UserDiscrepancyReportConfirmation from '@ui/Sections/TenantPage/UserDiscrepancyReportConfirmation';
import UserBackupReportConfirmation from '@ui/Sections/UserBackupPage/UserBackupReportConfirmation';
import useUserSelections from './pageHooks/useUserSelections';
import useUserBackupFecthing from './pageHooks/useUserBackupFecthing';
import { EMAIL } from './pageHooks/constants/emailConstants';
import { CONTACT } from './pageHooks/constants/contactConstants';
import { CALENDAR } from './pageHooks/constants/calendarConstants';
import { TASK } from './pageHooks/constants/taskConstants';
import { DRIVE } from './pageHooks/constants/driveConstants';
import { SHAREPOINT } from './pageHooks/constants/sharepointConstants';
import { MS_GROUP, MS_GROUP_TEXT } from './pageHooks/constants/msGroupConstants';
import { MS_CHAT, MS_CHAT_TEXT } from './pageHooks/constants/msChatConstants';
import { SD_DRIVE, SD_DRIVE_TEXT } from './pageHooks/constants/sdDriveConstants';

export default function UserBackupPage(props) {
  const { featureFlags, hasPermission } = useFeatureFlag();
  const { resellerId, organizationOwnerId } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { paginationState } = useSelector((state) => state.userBackupState);
  const { userBackupListState } = useSelector((state) => state.userBackupState);
  const {
    fetching,
    userBackupList,
    paginations,
    forbiddenErrorMessage,
    userBackupListCorsErrorMessage,
  } = userBackupListState;

  // filter
  const [onSearch, setOnSearch] = useState(false);
  const { filterState } = useSelector((state) => state.userBackupState);

  // tabs
  const tabNames = [
    EMAIL,
    CONTACT,
    CALENDAR,
    TASK,
    DRIVE,
    SHAREPOINT,
    MS_GROUP_TEXT,
    MS_CHAT_TEXT,
    SD_DRIVE_TEXT,
  ];
  const [backupType, setBackupType] = useState(EMAIL);
  const [selectTab, setSelectTab] = useState(0);
  const {
    handleFetchUserBackupListAsync,
    tableColumns,
    fetchParams,
    MenuActions,
    selectedAccount,
    requestUserDiscrepancyReportModal,
    setRequestUserDiscrepancyReportModal,
    requestUserBackupReportModal,
    setRequestUserBackupReportModal,
  } = useUserBackupFecthing();

  // checkbox
  const { extendList, extendColumns, withCheckbox, useCheckbox } = useUserSelections({
    backupType,
    list: userBackupList,
    columns: tableColumns(backupType),
  });

  const {
    resetSelect,
    selected,
    setSelected,
    listSortOptions,
    selectedDropdown,
    onDropdownSelect,
  } = useCheckbox;

  const handleOnTabClick = (e, newValue) => {
    resetSelect();
    dispatch(resetFilterState());
    dispatch(resetPaginationState());
    setSelectTab(newValue);
    let newBackupType = tabNames[newValue];
    if (newBackupType === MS_GROUP_TEXT) {
      newBackupType = MS_GROUP;
    } else if (newBackupType === MS_CHAT_TEXT) {
      newBackupType = MS_CHAT;
    } else if (newBackupType === SD_DRIVE_TEXT) {
      newBackupType = SD_DRIVE;
    }
    setBackupType(newBackupType.toLowerCase());
  };

  const fetchHandler = () => {
    setOnSearch(true);
    resetSelect();

    if (!fetching) {
      handleFetchUserBackupListAsync(
        paginationState.pageNumber,
        paginationState.pageSize,
        resellerId ?? filterState.resellerId,
        organizationOwnerId ?? filterState.organizationOwnerId,
        backupType,
        filterState
      );
      setOnSearch(false);
    }
  };

  const handleChangePage = (newPage) => {
    resetSelect();
    dispatch(setPaginationState({ pageNumber: newPage, pageSize: paginationState.pageSize }));
  };

  const handleChangePageSize = (newPageSize) => {
    resetSelect();
    dispatch(setPaginationState({ pageNumber: 1, pageSize: newPageSize }));
  };

  useEffect(() => {
    if (onSearch) {
      fetchHandler();
    }
  }, [onSearch]);

  useEffect(() => {
    fetchHandler();
  }, [paginationState.pageNumber, paginationState.pageSize]);

  useEffect(() => {
    dispatch(resetPaginationState());
    fetchHandler();
  }, [backupType]);

  return (
    <>
      <HeaderPageBlock>Backups</HeaderPageBlock>
      {userBackupListCorsErrorMessage && (
        <QboAlert type="error" style={{ fontSize: '1.15em' }}>
          {userBackupListCorsErrorMessage}
        </QboAlert>
      )}
      <QboTabs
        noPadding
        isMainWrappe
        tabIndex={selectTab}
        title={tabNames}
        onChange={handleOnTabClick}
        sx={{ textTransform: 'capitalize', marginTop: 2 }}
      />
      <FilterSection
        backupType={backupType}
        setOnSearch={setOnSearch}
        organizationOwnerId={organizationOwnerId}
        resellerId={resellerId}
      />

      <QboCard noPadding isMainWrapper>
        <>
          {(userBackupList?.length || fetching) && (
            <>
              {backupType === EMAIL && (
                <UserEmailBackupToolsSection
                  list={extendList}
                  selectedIds={selected}
                  onCloseModal={() => {
                    fetchHandler();
                  }}
                  fetchParams={fetchParams}
                  hasResults={!!userBackupList?.length}
                />
              )}

              <QboSimpleDataTable
                sx={{ width: '100%' }}
                rows={extendList}
                header={extendColumns}
                loading={fetching}
                withCheckboxSelection={withCheckbox}
                withMinimumCheckbox={withCheckbox}
                selected={selected}
                setSelected={setSelected}
                listSortOptions={listSortOptions}
                selectedDropdown={selectedDropdown}
                onDropdownSelect={onDropdownSelect}
                menuActions={backupType === EMAIL && MenuActions.length > 0 ? MenuActions : null}
              />
            </>
          )}
          {(userBackupList?.length || fetching) && (
            <QboCardListItem
              justifyContent="right"
              alignItems="center"
              style={{ paddingRight: '1.5em' }}
              rightContent={
                <QboPagination
                  hasNextLink={paginations?.HasNext}
                  hasPrevLink={paginations?.HasPrevious}
                  onClickPrevLink={() => {
                    const newPageNumber = paginationState.pageNumber - 1;
                    handleChangePage(newPageNumber);
                  }}
                  onClickNextLink={() => {
                    const newPageNumber = paginationState.pageNumber + 1;
                    handleChangePage(newPageNumber);
                  }}
                  onChangePageNumber={handleChangePageSize}
                  pageNumber={paginationState.pageSize}
                  paginationLabel="Page size"
                />
              }
            />
          )}

          {!userBackupList?.length && !fetching && (
            <NoStatusSection forbiddenErrorMessage={forbiddenErrorMessage} />
          )}
        </>
      </QboCard>

      <RequestModalTool
        key={featureFlags.UserDiscrepancyReport}
        serviceType={featureFlags.UserDiscrepancyReport}
        selectedAccount={selectedAccount}
        showRequestModal={requestUserDiscrepancyReportModal}
        title={t('tenant_page.user_discrepancy_report.title')}
        closeSetupModal={() => {
          setRequestUserDiscrepancyReportModal(false);
        }}
        topBarElement={
          <QboAlert variant="outlined" type="info" className="UserBackupPage__information-box">
            <QboTypography noWrap={false} className="UserBackupPage__information-text">
              {t('tenant_page.user_discrepancy_report.information')}
            </QboTypography>
          </QboAlert>
        }>
        <UserDiscrepancyReportConfirmation selectedAccount={selectedAccount} />
      </RequestModalTool>

      <RequestModalTool
        key={featureFlags.UserBackupReport}
        serviceType={featureFlags.UserBackupReport}
        selectedAccount={selectedAccount}
        showRequestModal={requestUserBackupReportModal}
        title={t('user_backup_page.user_backup_report.title')}
        closeSetupModal={() => {
          setRequestUserBackupReportModal(false);
        }}
        topBarElement={
          <QboAlert variant="outlined" type="info" className="UserBackupPage__information-box">
            <QboTypography noWrap={false} className="UserBackupPage__information-text">
              {t('user_backup_page.user_backup_report.information')}
            </QboTypography>
          </QboAlert>
        }>
        <UserBackupReportConfirmation selectedAccount={selectedAccount} />
      </RequestModalTool>
    </>
  );
}

UserBackupPage.propTypes = {
  resellerId: PropTypes.number,
  organizationOwnerId: PropTypes.number,
};

UserBackupPage.defaultProps = {
  resellerId: null,
  organizationOwnerId: null,
};
