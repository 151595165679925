import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import applicationReducer from '@features/application/applicationSlice';
import todosReducer from '@features/todo/todoSlice';
import authReducer from '@features/auth/authSlice';
import formReducer from '@features/form/formSlice';
import listingReducer from '@features/listing/listingSlice';
import regionReducer from '@features/regions/regionsSlice';
import refTokenReducer from '@features/auth/refreshTokenSlice';
import tokenAccessReducer from '@features/auth/accessTokenSlice';
import silentToken from '@features/auth/silentTokenSlice';
import mailChange from '@features/mailBox/mailboxChangeSlice';
import mailRequestState from '@features/mailBox/getMailBoxChangeRequestSlice';
import mailSync from '@features/mailBox/mailBoxSyncSlice';
import mailSyncRequest from '@features/mailBox/getMailBoxSyncSlice';
import getExcludedMailboxesReducer from '@features/excludedMailboxes/getExcludedMailboxesSlice';
import excludedMailboxesFormReducer from '@features/excludedMailboxes/excludedMailboxesSlice';
import autoDiscoveryReducer from '@features/autoDiscovery/getAutoDiscoverySlice';
import autoDiscoveryFormReducer from '@features/autoDiscovery/autoDiscoverySlice';
import ingestionReducer from '@features/ingestion/getIngestionSlice';
import detailsOfIngestionListState from '@features/ingestion/getDetailsOfIngestionListSlice';
import ingestionFormReducer from '@features/ingestion/ingestionSlice';
import transferSubresellerReducer from '@features/transferSubreseller/transferSubresellerSlice';
import transferSubscriptionReducer from '@features/transferSubscription/transferSubscriptionSlice';
import systemStatusReducer from '@features/systemStatus/systemStatusSlice';
import featureFlag from '@features/featureFlag/featureFlagSlice';
import bulkPurgeList from '@features/mailboxBulkPurge/getMailboxBulkPurgeSlice';
import createBulkPurgeRequest from '@features/mailboxBulkPurge/mailboxBulkPurgeSlice';
import eachListOfBulkPurgeList from '@features/mailboxBulkPurge/getMailboxBulkPurgeListSlice';
import statusOfBulkPurgeItem from '@features/mailboxBulkPurge/getMailboxBulkPurgeStatusSlice';
import domainBackupsSliceState from '@features/domainBackups/getDomainBackupsSlice';
import domainBackupsState from '@features/domainBackups/createDomainBackupsSlice';
import getRecreateBackupAdminsReducer from '@features/recreateBackupAdmins/getRecreateBackupAdminsSlice';
import recreateBackupAdminsReducer from '@features/recreateBackupAdmins/recreateBackupAdminsSlice';
import runLicenseCheckerReducer from '@features/runLicenseChecker/runLicenseCheckerSlice';
import runAutodiscoverReducer from '@features/runAutodiscover/runAutodiscoverSlice';
import invalidateServiceTokenReducer from '@features/invalidateServiceToken/invalidateServiceTokenSlice';
import runBackupReducer from '@features/runBackup/runBackupSlice';
import getPurgeM365TenantsReducer from '@features/purgeM365Tenant/getPurgeM365TenantsSlice';
import purgeM365TenantReducer from '@features/purgeM365Tenant/purgeM365TenantSlice';
import transferSubresellerState from '@features/transferSubresellerService/getTransferSubresellerSlice';
import transferSubscriptionState from '@features/transferSubscriptionService/getTransferSubscriptionSlice';
import transferSubscriptionInitiateState from '@features/transferSubscriptionInitiatePageService/getTransferSubscriptionInitiateSlice';
import transferSubscriptionInitiateFormState from '@features/transferSubscriptionInitiatePageService/createTransferSubscriptionInitiateSlice';
import ingestionReports from '@features/ingestion/ingestionReportsSlice';
import ingestionReportsListItemState from '@features/ingestion/ingestionReportsListItemSlice';
import ingestionJobState from '@features/ingestion/ingestionJobSlice';
import ingestionReRunSlice from '@features/ingestion/ingestionReRunSlice';
import ingestionFailedEml from '@features/ingestion/ingestionFailedEmlSlice';
import seatUsageReports from '@features/seatUsageReport/seatUsageSlice';
import seatUsageSummaryReport from '@features/seatUsageReport/seatUsageSummarySlice';
import exportSeatUsageReportState from '@features/seatUsageReport/exportSeatUsageSlice';
import removeIngestionSlice from '@features/ingestion/removeIngestionSlice';
import submitPurgeExternalAccountFormState from '@features/purgeExternalAccount/purgeExternalAccountSlice';
import getPurgeExternalAccountSlice from '@features/purgeExternalAccount/getPurgeExternalAccountSlice';
import submitPurgeSharedDriveFormState from '@features/purgeSharedDrive/purgeSharedDriveSlice';
import getPurgeSharedDriveSlice from '@features/purgeSharedDrive/getPurgeSharedDriveSlice';
import getTransferPartnerSlice from '@features/transferPartner/getTransferPartnerSlice';
import submitTransferPartnerSlice from '@features/transferPartner/submitTransferPartnerSlice';
import validateTransferPartnerSlice from '@features/transferPartner/validateTransferPartnerSlice';
import partnersListState from '@features/partnerList/partnersListSlice';
import OrganizationsListState from '@features/organizationList/organizationsListSlice';
import exportOrganizationsSlice from '@features/organizationList/exportOrganizationsSlice';
import organizationInformationReducer from '@features/organizationInformation/organizationInformationSlice';
import m365TenantReducer from '@features/m365Tenant/m365TenantSlice';
import m365TenantDetailInfoReducer from '@features/m365TenantDetailsInfo/m365TenantDetailsInfoSlice';
import m365UsersTenantReducer from '@features/m365UsersTenant/m365UsersTenantSlice';
import m365SitesTenantReducer from '@features/m365SitesTenant/m365SitesTenantSlice';
import gwsTenantDetailInfoReducer from '@features/gwsTenantDetailsInfo/gwsTenantDetailsInfoSlice';
import gwsTenantReducer from '@features/gwsTenant/gwsTenantSlice';
import hexTenantReducer from '@features/hexTenant/hexTenantSlice';
import plansListState from '@features/plansList/plansListSlice';
import usersListState from '@features/usersList/usersListSlice';
import submitSeatCountChangeFormState from '@features/seatCountChange/seatCountChangeSlice';
import getSeatCountChangeSlice from '@features/seatCountChange/getSeatCountChangeSlice';
import selectedResellerConfigs from '@features/resellerConfigs/getSelectedResellerConfigsSlice';
import getResellerNotificationsConfigState from '@features/resellerConfigs/getResellerNotificationsConfigSlice';
import subResellerList from '@features/subReseller/getSubResellerListSlice';
import monthlyBackupReports from '@features/monthlyBackupReport/monthlyBackupSlice';
import exportMonthlyBackupReportState from '@features/monthlyBackupReport/exportMonthlyBackupSlice';
import tenantAuthErrorsReducer from '@features/tenantAuthErrors/getTenantAuthErrorsSlice';
import tenantAuthErrorsFormReducer from '@features/tenantAuthErrors/tenantAuthErrorsSlice';
import ingestionsQueuedSlice from '@features/ingestion/getIngestionQueuedSlice';
import ingestionsInProgressSlice from '@features/ingestion/getIngestionInProgressSlice';
import partnersFilterListState from '@features/partnerList/partnersFilterListSlice';
import organizationFilterListState from '@features/organizationList/getOrganizationFilterListSlice';
import parentFilterListState from '@features/organizationList/getParentFilterListSlice';
import switchSPATypeReducer from '@features/swicthSPAType/switchSPATypeSlice';
import credentialsSPAinfoReducer from '@features/swicthSPAType/credentilasSPAinfoSlice';
import m365TenantDetailReducer from '@features/m365TenantDetail/m365TenantDetailSlice';
import partnerDetailReducer from '@features/partnerDetail/partnerDetailSlice';
import getPurgeGWSTenantsReducer from '@features/purgeGWSTenant/getPurgeGWSTenantsSlice';
import purgeGWSTenantReducer from '@features/purgeGWSTenant/purgeGWSTenantSlice';
import gwsTenantDetailReducer from '@features/gwsTenantDetail/gwsTenantDetailSlice';
import userBackupReducer from '@features/userBackup/userBackupInfoSlice';
import getChangeBackupAdminsReducer from '@features/changeBackupAdmin/getChangeBackupAdminsSlice';
import changeBackupAdminReducer from '@features/changeBackupAdmin/changeBackupAdminSlice';
import validateChangeBackupAdminReducer from '@features/changeBackupAdmin/validateChangeBackupAdminSlice';
import hexTenantDetailsInfoList from '@features/hexTenantDetails/hexTenantDetailsInfoSlice';
import mailboxChangeStatusReducer from '@features/mailBox/mailboxChangeStatusSlice';
import mailboxChangeDetailReducer from '@features/mailBox/mailboxChangeDetailSlice';
import getSwitchCredentialStatusReducer from '@features/switchCredential/getSwitchCredentialStatusSlice';
import getSwitchCredentialRequestReducer from '@features/switchCredential/getSwitchCredentialRequestSlice';
import getSwitchCredentialItemsReducer from '@features/switchCredential/getSwitchCredentialItemsSlice';
import createSwitchCredentialReducer from '@features/switchCredential/createSwitchCredentialSlice';
import organizationUsersListReducer from '@features/organizationUsersList/organizationUsersListSlice';
import gwsUsersTenantReducer from '@features/gwsUsersTenant/gwsUsersTenantSlice';
import gwsSharedDrivesTenantReducer from '@features/gwsSharedDrivesTenant/gwsSharedDrivesTenantSlice';
import toolsRequestHistorySlice from '@features/toolsRequestHistory/toolsRequestHistorySlice';
import toolsRequestDetailsSlice from '@features/toolsRequestHistory/toolsRequestDetailsSlice';
import serviceTypesSlice from '@features/toolsRequestHistory/serviceTypesSlice';
import removeIngestionJobSlice from '@features/ingestion/removeIngestionJobSlice';
import toggleEnforceSSOSlice from '@features/updateEnforceSSO/toggleEnforceSSOSlice';
import backupStatusReducer from '@features/backupStatus/backupStatusInfoSlice';
import validateToggleEnforceSSOSlice from '@features/updateEnforceSSO/validateToggleEnforceSSOSlice';
import exportM365TenantSlice from '@features/m365Tenant/exportM365TenantSlice';
import tenantSizeReportReducer from '@features/tenantSizeReport/tenantSizeReportSlice';
import exportTenantSizeReportReducer from '@features/tenantSizeReport/exportTenantSizeReportSlice';
import bulkCreateOrganizationSlice from '@features/bulkCreateOrganization/bulkCreateOrganizationSlice';
import getBulkCreateOrganizationItemsSlice from '@features/bulkCreateOrganization/getBulkCreateOrganizationItemsSlice';
import getBulkCreateOrganizationTemplateSlice from '@features/bulkCreateOrganization/getBulkCreateOrganizationTemplateSlice';
import bulkCreateSubResellerSlice from '@features/bulkCreateSubReseller/bulkCreateSubResellerSlice';
import getBulkCreateSubResellerItemsSlice from '@features/bulkCreateSubReseller/getBulkCreateSubResellerItemsSlice';
import getBulkCreateSubResellerTemplateSlice from '@features/bulkCreateSubReseller/getBulkCreateSubResellerTemplateSlice';
import userDiscrepancyReportReducer from '@features/userDiscrepancyReport/userDiscrepancyReportSlice';
import exportUserDiscrepancyReportReducer from '@features/userDiscrepancyReport/exportUserDiscrepancyReportSlice';
import getInvoicesListSlice from '@features/invoice/getInvoicesListSlice';
import checkXeroSyncRunningSlice from '@features/invoice/checkXeroSyncRunningSlice';
import runXeroSyncSlice from '@features/invoice/runXeroSyncSlice';
import userBackupReportSlice from '@features/userBackupReport/userBackupReportSlice';
import exportUserBackupReportSlice from '@features/userBackupReport/exportUserBackupReportSlice';
import ownerListSlice from '@features/dmeSettings/ownerListSlice';
import channelListSlice from '@features/dmeSettings/channelListSlice';

const reducer = {
  application: applicationReducer,
  todos: todosReducer,
  auth: authReducer,
  form: formReducer,
  list: listingReducer,
  silent: silentToken,
  region: regionReducer,
  refToken: refTokenReducer,
  tokenAccess: tokenAccessReducer,
  mail: mailChange,
  mailRequest: mailRequestState,
  sync: mailSync,
  getAutoDiscovery: autoDiscoveryReducer,
  autoDiscoveryForm: autoDiscoveryFormReducer,
  ingestion: ingestionReducer,
  detailsOfIngestions: detailsOfIngestionListState,
  ingestionForm: ingestionFormReducer,
  flags: featureFlag,
  bulkPurgeListData: bulkPurgeList,
  bulkFormData: createBulkPurgeRequest,
  bulkPurgeListState: eachListOfBulkPurgeList,
  syncRequest: mailSyncRequest,
  excludedMail: getExcludedMailboxesReducer,
  excludedMailForm: excludedMailboxesFormReducer,
  TransferSubreseller: transferSubresellerReducer,
  TransferSubscription: transferSubscriptionReducer,
  SystemStatus: systemStatusReducer,
  bulkPurgeStatus: statusOfBulkPurgeItem,
  domainState: domainBackupsSliceState,
  domainFormState: domainBackupsState,
  getPurgeM365Tenants: getPurgeM365TenantsReducer,
  purgeM365Tenant: purgeM365TenantReducer,
  getRecreateBackupAdmins: getRecreateBackupAdminsReducer,
  recreateBackupAdmins: recreateBackupAdminsReducer,
  runLicenseChecker: runLicenseCheckerReducer,
  runAutodiscover: runAutodiscoverReducer,
  invalidateServiceToken: invalidateServiceTokenReducer,
  runBackup: runBackupReducer,
  getTransferSubresellerState: transferSubresellerState,
  getTransferSubscriptionState: transferSubscriptionState,
  initiateState: transferSubscriptionInitiateState,
  initiateRequestState: transferSubscriptionInitiateFormState,
  ingestionReportsState: ingestionReports,
  ingestionReportListItem: ingestionReportsListItemState,
  ingestionJob: ingestionJobState,
  ingestionJobReRunState: ingestionReRunSlice,
  ingestionFailedEmlState: ingestionFailedEml,
  seatUsageReportsData: seatUsageReports,
  seatUsageSummary: seatUsageSummaryReport,
  exportSeatUsageReport: exportSeatUsageReportState,
  removeIngestionState: removeIngestionSlice,
  submitPurgeExternalAccountState: submitPurgeExternalAccountFormState,
  getPurgeExternalAccountState: getPurgeExternalAccountSlice,
  submitPurgeSharedDriveState: submitPurgeSharedDriveFormState,
  getPurgeSharedDriveState: getPurgeSharedDriveSlice,
  getTransferPartnerState: getTransferPartnerSlice,
  submitTransferPartnerState: submitTransferPartnerSlice,
  validateTransferPartnerState: validateTransferPartnerSlice,
  partnersList: partnersListState,
  organizationsList: OrganizationsListState,
  exportOrganizations: exportOrganizationsSlice,
  organizationInformation: organizationInformationReducer,
  m365Tenant: m365TenantReducer,
  exportM365Tenant: exportM365TenantSlice,
  m365TenantDetailsInfo: m365TenantDetailInfoReducer,
  m365UsersTenant: m365UsersTenantReducer,
  m365SitesTenant: m365SitesTenantReducer,
  gwsTenantDetailsInfo: gwsTenantDetailInfoReducer,
  gwsTenant: gwsTenantReducer,
  hexTenant: hexTenantReducer,
  plansList: plansListState,
  usersList: usersListState,
  organizationUsersList: organizationUsersListReducer,
  submitSeatCountChangeState: submitSeatCountChangeFormState,
  getSeatCountChangeState: getSeatCountChangeSlice,
  selectedResellerConfigsState: selectedResellerConfigs,
  resellerNotificationsConfigState: getResellerNotificationsConfigState,
  subResellerListState: subResellerList,
  monthlyBackupData: monthlyBackupReports,
  exportMonthlyBackupReport: exportMonthlyBackupReportState,
  getTenantAuthErrors: tenantAuthErrorsReducer,
  tenantAuthErrorsForm: tenantAuthErrorsFormReducer,
  ingestionsQueuedState: ingestionsQueuedSlice,
  ingestionsInProgressState: ingestionsInProgressSlice,
  partnersFilterList: partnersFilterListState,
  organizationFilterList: organizationFilterListState,
  parentFilterList: parentFilterListState,
  switchSPATypeState: switchSPATypeReducer,
  credentialsSPAinfoState: credentialsSPAinfoReducer,
  m365TenantDetail: m365TenantDetailReducer,
  partnerDetail: partnerDetailReducer,
  getPurgeGWSTenants: getPurgeGWSTenantsReducer,
  purgeGWSTenant: purgeGWSTenantReducer,
  gwsTenantDetail: gwsTenantDetailReducer,
  userBackupState: userBackupReducer,
  getChangeBackupAdmins: getChangeBackupAdminsReducer,
  changeBackupAdmin: changeBackupAdminReducer,
  validateChangeBackupAdmin: validateChangeBackupAdminReducer,
  hexTenantDetailsInfo: hexTenantDetailsInfoList,
  mailboxChangeStatus: mailboxChangeStatusReducer,
  mailboxChangeDetail: mailboxChangeDetailReducer,
  getSwitchCredentialStatus: getSwitchCredentialStatusReducer,
  getSwitchCredentialRequest: getSwitchCredentialRequestReducer,
  getSwitchCredentialItems: getSwitchCredentialItemsReducer,
  createSwitchCredential: createSwitchCredentialReducer,
  gwsUsersTenant: gwsUsersTenantReducer,
  gwsSharedDrivesTenant: gwsSharedDrivesTenantReducer,
  toolsRequestHistory: toolsRequestHistorySlice,
  toolsRequestDetails: toolsRequestDetailsSlice,
  serviceTypes: serviceTypesSlice,
  removeIngestionJobState: removeIngestionJobSlice,
  toggleEnforceSSO: toggleEnforceSSOSlice,
  validateToggleEnforceSSO: validateToggleEnforceSSOSlice,
  backupStatusState: backupStatusReducer,
  tenantSizeReport: tenantSizeReportReducer,
  exportTenantSizeReport: exportTenantSizeReportReducer,
  bulkCreateOrganization: bulkCreateOrganizationSlice,
  getBulkCreateOrganizationItems: getBulkCreateOrganizationItemsSlice,
  getBulkCreateOrganizationTemplate: getBulkCreateOrganizationTemplateSlice,
  bulkCreateSubReseller: bulkCreateSubResellerSlice,
  getBulkCreateSubResellerItems: getBulkCreateSubResellerItemsSlice,
  getBulkCreateSubResellerTemplate: getBulkCreateSubResellerTemplateSlice,
  userDiscrepancyReport: userDiscrepancyReportReducer,
  exportUserDiscrepancyReport: exportUserDiscrepancyReportReducer,
  getInvoicesList: getInvoicesListSlice,
  checkXeroSyncRunning: checkXeroSyncRunningSlice,
  runXeroSync: runXeroSyncSlice,
  userBackupReport: userBackupReportSlice,
  exportUserBackupReport: exportUserBackupReportSlice,
  ownerList: ownerListSlice,
  channelList: channelListSlice,
};

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    if (['production', 'test'].includes(process.env.NODE_ENV)) {
      return getDefaultMiddleware({
        serializableCheck: false,
      });
    }
    return getDefaultMiddleware({
      serializableCheck: false,
    }).concat(logger);
  },
  devTools: process.env.NODE_ENV !== 'production',
});

export default store;
